<template>
  <div class="flex flex-row w-full pb-2" v-if="steps[stepIndex].condition_set">
    <div
      :class="{
        'border-spreedly-red':
          (!store.currentOrganization.payment_method_management_enabled &&
            hasBinDimension) ||
          hasInvalidOptions(),
      }"
      class="bg-white rounded-lg border border-spreedly-gray-300 p-2 flex flex-row justify-between min-w-[678px] w-full"
    >
      <div class="flex flex-col">
        <div class="flex flex-row justify-between mx-2 mb-2">
          <div
            class="text-spreedly-gray-600 mr-4 self-center flex flex-row whitespace-nowrap"
          >
            <i18n-t
              scope="global"
              keypath="workflows.conditionHeader"
              tag="p"
              :plural="steps[stepIndex].condition_set!.conditions.length"
              class="font-bold text-spreedly-gray-600"
            >
              <template v-slot:selection>
                <Dropdown
                  class="workflow-title-dropdown"
                  aria-label="operator"
                  v-model="v.operator.$model"
                  option-value="value"
                  option-label="label"
                  :input-id="`dimension-operator-${stepIndex}`"
                  :disabled="!hasPermission"
                  :options="[
                    { value: 'AND', label: 'all' },
                    { value: 'OR', label: 'any' },
                  ]"
                  :pt="{
                    root: {
                      class:
                        '!mb-0 !border-t-0 !rounded-none !pr-0 !border-b-spreedly-blue-600 !border-x-0 !w-fit self-center !mx-2 font-sans font-bold',
                    },

                    input: { class: 'px-0  !text-spreedly-gray-600' },
                  }"
                />
              </template>
            </i18n-t>
          </div>

          <div class="self-center">
            <Button
              v-if="steps[stepIndex].condition_set!.edit_mode"
              type="button"
              :disabled="!conditionSetCollapsable"
              :aria-label="`collapse-condition-${stepIndex}`"
              :id="`collapse-condition-${stepIndex}`"
              @click="steps[stepIndex].condition_set!.edit_mode = false"
              v-tooltip="$t('collapse')"
              icon="pi pi-arrow-down-left-and-arrow-up-right-to-center"
              class="p-button-text text-spreedly-blue-600 hover:text-spreedly-blue-700 hover:enabled:text-spreedly-blue-700 focus:text-spreedly-blue-700 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
              rounded
            ></Button>
          </div>
        </div>
        <div v-if="steps[stepIndex].condition_set?.edit_mode">
          <WorkflowCondition
            v-for="(condition, index) in conditionSet.conditions"
            :step-index="stepIndex"
            :condition-index="index"
            :condition="condition"
            :key="condition.uuid"
          >
            <template v-slot:remove>
              <Button
                v-if="displayRemoveDimensionButton(index)"
                type="button"
                :aria-label="$t('remove')"
                :id="`remove-dimension-${stepIndex}-${index}`"
                @click="$emit('removeCondition', index)"
                :disabled="!hasPermission"
                icon="pi pi-times"
                v-tooltip="$t('workflows.removeDimension')"
                class="remove-dimension p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
                rounded
              ></Button>
            </template>
          </WorkflowCondition>
        </div>
        <div
          class="flex flex-row mt-4"
          v-if="steps[stepIndex].condition_set?.edit_mode &&
                      steps[stepIndex].condition_set?.conditions?.length! <=
                      conditionTypes.length - 1
                    "
        >
          <Button
            :disabled="!hasPermission"
            :id="`add-condition-${stepIndex}`"
            type="button"
            @click="addCondition(stepIndex)"
            :label="$t('workflows.addCondition')"
            icon="pi pi-plus"
            class="p-button-text !text-spreedly-blue-600 hover:text-spreedly-blue-700 bg-transparent !shadow-none ml-2"
          >
          </Button>
        </div>

        <div
          v-else-if="!steps[stepIndex].condition_set?.edit_mode"
          class="flex flex-wrap mx-2"
        >
          <Tag
            v-for="condition in conditionSet.conditions"
            :pt="{
              root: {
                class: {
                  'font-sans border border-spreedly-teal bg-spreedly-teal-light text-spreedly-gray-600 mb-1':
                    store.currentOrganization
                      .payment_method_management_enabled ||
                    (!store.currentOrganization
                      .payment_method_management_enabled &&
                      !binConditions.includes(condition.condition_type)),
                  'font-sans border border-warning-orange bg-warning-orange-light text-spreedly-gray-600 mb-1':
                    (!store.currentOrganization
                      .payment_method_management_enabled &&
                      binConditions.includes(condition.condition_type)) ||
                    !optionsAllAvailable(condition),
                },
              },
              icon: { class: 'text-warning-orange' },
              value: {
                class: 'font-bold',
              },
            }"
            :icon="
              (!store.currentOrganization.payment_method_management_enabled &&
                binConditions.includes(condition.condition_type)) ||
              !optionsAllAvailable(condition)
                ? 'pi pi-exclamation-triangle'
                : ''
            "
            class="mr-4 whitespace-nowrap"
            :value="
              sentenceCase(condition.condition_type).replace('Bin', 'BIN')
            "
            severity="composer"
            v-tooltip="
              !store.currentOrganization.payment_method_management_enabled &&
              binConditions.includes(condition.condition_type)
                ? $t('dimensions.binDimensionNoLongerAvailable', {
                    condition: sentenceCase(condition.condition_type).replace(
                      'Bin',
                      'BIN'
                    ),
                  })
                : !optionsAllAvailable(condition)
                ? $t('dimensions.optionNoLongerExists', {
                    dimension: sentenceCase(condition.condition_type)
                      .toLowerCase()
                      .replace('bin', 'BIN'),
                  })
                : ''
            "
            rounded
          ></Tag>
        </div>
      </div>

      <div
        class="self-center"
        v-if="!steps[stepIndex].condition_set!.edit_mode"
      >
        <Button
          :id="`edit-view-conditions-${stepIndex}`"
          type="button"
          :label="
            hasPermission ? sentenceCase($t('edit')) : sentenceCase($t('view'))
          "
          @click="openEditor()"
          class="mx-2 p-button-text !text-spreedly-blue-600 hover:text-spreedly-blue-700 bg-transparent !shadow-none"
        >
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { addCondition, useWorkflow } from "@/composables/useWorkflow";
import WorkflowCondition from "@/components/WorkflowCondition.vue";
import {
  type Options,
  PAYMENT_METHOD_GATEWAY_TYPE,
  PAYMENT_METHOD_TYPE,
  TRANSACTION_METADATA,
  type WorkflowConditionSet as ConditionSet,
  type WorkflowCondition as Condition,
} from "@/services/WorkflowService";
import { required } from "@/i18n/i18n-validators";
import { sentenceCase } from "@/services/HelperService";
import Button from "primevue/button";
import { useSettingsStore } from "@/stores/SettingsStore";
import Tag from "primevue/tag";
import Dropdown from "primevue/dropdown";
const store = useSettingsStore();

const { steps, action, conditionTypes, options } = useWorkflow();

const emit = defineEmits(["removeCondition"]);

const props = defineProps<{
  stepIndex: number;
  conditionSet: ConditionSet;
}>();

const binConditions = conditionTypes.value
  .filter((t) => t.av_required)
  .map((t) => t.name);

const hasBinDimension = computed(() => {
  return props.conditionSet.conditions
    .map((c) => c.condition_type)
    .some((item) => binConditions.includes(item));
});

const conditionSetCollapsable = computed(() => {
  return (
    steps.value[props.stepIndex]!.condition_set?.conditions?.length &&
    !v.value.$invalid
  );
});

const optionKey = (conditionType: string) => {
  switch (conditionType) {
    case "currency":
      return "currencies";
    case "issuing_country":
      return "issuing_countries";
    case PAYMENT_METHOD_TYPE:
      return "payment_method_types";
    case PAYMENT_METHOD_GATEWAY_TYPE:
      return "payment_method_gateway_types";
    default:
      return conditionType;
  }
};

const optionValue = (conditionType: string) => {
  switch (conditionType) {
    case "currency":
      return "iso_code";
    case "issuing_country":
      return "iso_country_a3";
    case PAYMENT_METHOD_GATEWAY_TYPE:
      return "gateway_type";
    default:
      return undefined;
  }
};

const hasInvalidOptions = () => {
  let hasInvalid = false;

  props.conditionSet.conditions?.forEach((c) => {
    if (!optionsAllAvailable(c)) {
      hasInvalid = true;
      return;
    }
  });

  return hasInvalid;
};
const optionsAllAvailable = (condition: Condition) => {
  let opt = options.value[optionKey(condition.condition_type) as keyof Options];
  if (!opt) return true;
  const optionVal = optionValue(condition.condition_type);
  if (optionVal) {
    opt = opt.map((o) => o[optionVal]);
  }
  return condition.values.every((item) => opt.includes(item));
};
const openEditor = () => {
  steps.value[props.stepIndex]!.condition_set!.edit_mode = true;
};

const state = reactive(props.conditionSet);
const rules = {
  operator: { required },
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

function displayRemoveDimensionButton(index: number) {
  //display remove dimension button if there are more than one condition
  // if condition type is transaction_metadata display the remove button only if there is one
  // key value pair, otherwise WorkflowCondition component will display the remove key value pair button
  return (
    steps.value[props.stepIndex].condition_set?.conditions?.length &&
    steps.value[props.stepIndex].condition_set!.conditions!.length > 1 &&
    (steps.value[props.stepIndex].condition_set!.conditions[index]
      .condition_type !== TRANSACTION_METADATA ||
      (steps.value[props.stepIndex].condition_set!.conditions[index]
        .condition_type === TRANSACTION_METADATA &&
        steps.value[props.stepIndex].condition_set!.conditions[index].values
          .length === 1))
  );
}
</script>
